<template>
  <a-drawer class="three-zhizhu-add" title="三支柱相关" @close="onClose" :width="720" :visible="addVisiable" :maskClosable="false">

    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item label="标题" prop="title" v-bind="formItemLayout">
        <a-input v-model="form.title" />
      </a-form-model-item>
      <a-form-model-item label="年份" prop="year" v-bind="formItemLayout" >
        <a-input-number placeholder="年份" v-model="form.year" :min="2000" :max="2200" />
      </a-form-model-item>
      <a-form-model-item label="月份" prop="month" v-bind="formItemLayout" >
        <a-input-number placeholder="月份" v-model="form.month" :min="1" :max="12" />
      </a-form-model-item>
      <a-form-model-item label="三支柱文件" prop="title" v-bind="formItemLayout">
        <upload-enclosure :path="EXCEL_THREEZHHIZHU" :acceptArr="acceptArr" :enclosure-list="enclosureList" @_change="handleUploadEnclosureChange"></upload-enclosure>
      </a-form-model-item>
    </a-form-model>
    <div class="drawer-bootom-button">
      <!-- <a-popconfirm title="确定放弃编辑？" @confirm="onClose" okText="确定" cancelText="取消">
        <a-button style="margin-right: .8rem">取消</a-button>
      </a-popconfirm> -->
			<a-button @click="onClose" style="margin-right: .8rem">取消</a-button>
      <a-button @click="handleSubmit" type="primary">提交</a-button>
    </div>
  </a-drawer>
</template>

<script>
import moment from "moment";
import {EXCEL_THREEZHHIZHU} from "../../../utils/upload-path-map"
import UploadEnclosure from "../../../components/upload-enclosure/UploadEnclosure"

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 }
}

export default {
  name: "ThreeZhizhuAdd",
  components: {UploadEnclosure},
  props: {
    addVisiable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formItemLayout,
      EXCEL_THREEZHHIZHU,
      enclosureList: [],
      acceptArr: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
      form: {
        title: undefined,
        year: moment().year(),
        month: moment().month() + 1,
        enclosure: undefined
      },
      rules: {
        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' }
        ],
        enclosure: [
          { required: true, message: '上传文件不能为空', trigger: 'change' }
        ],
      }
    }
  },
  methods: {
    onClose() {
      // this.$refs.ruleForm.resetFields();
      this.reset();
      this.$emit("close");
    },
    reset() {
      this.$refs.ruleForm.resetFields();
      this.form.title = undefined
      this.enclosureList = [];
    },
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$post("three-zhizhu", {...this.form})
            .then(r => {
              console.log("添加三支柱文件的返回值 ==> ", r.data);
              this.reset();
              this.$emit("success");
            })
        } else {
          return false;
        }
      });
    },
    handleUploadEnclosureChange(list, urls) {
      // console.log("上传附件的返回值===>", list, urls);
      this.enclosureList = list;
      this.form.enclosure = urls.length?urls[0]['url']:null;
    }
  }
}
</script>

<style>

</style>